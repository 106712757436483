import React from "react";
import { Link, graphql } from "gatsby";
import Dinero from "dinero.js";

import AniLink from "gatsby-plugin-transition-link/AniLink";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Layout from "../components/layout";
import Image from "../components/image";
import SEO from "../components/seo";

import PageHeader from "../components/page/header";
import PageDivider from "../components/page/divider";
import PageFooter from "../components/page/footer";

import CheckoutButton from "../components/checkout-button.js";

import "./registry-item.scss";

const RegistryItemPage = ({ data }) => {
  const sku = data.allStripeSku.nodes[0];
  const name = sku.product.name;
  const description = sku.product.metadata.description;
  const inventory = sku.product.metadata.inventory;

  const has_inventory = sku.product.metadata.inventory > 0;

  return (
    <Layout page="registry-item">
      <SEO title="Registry Item" />

      <Row className="page-content">
        <Col sm />
        <Col className="col-sm-10 col-12">
          <AniLink fade to="/registry/" duration={0.35}>
            ← Back to Registry
          </AniLink>
        </Col>
        <Col sm />
      </Row>

      <Row className="page-content">
        <Col />
        <Col className="col-sm-6 col-12">
          <img src={sku.image} />
        </Col>
        <Col className="col-sm-4 col-12">
          <h2>{name}</h2>
          <h5>{Dinero({ amount: sku.price }).toFormat()}</h5>
          <p>{description}</p>
          {has_inventory && (
            <>
              <p>
                <i>Inventory: {inventory}</i>
              </p>
              <CheckoutButton sku={sku.id} inventory={inventory} />
            </>
          )}
          {!has_inventory && (
            <>
              <p>
                <i>Sold Out</i>
              </p>
            </>
          )}
        </Col>
        <Col />
      </Row>

      <PageFooter />
    </Layout>
  );
};

export const query = graphql`
  query($id: String!) {
    allStripeSku(filter: { id: { eq: $id } }) {
      nodes {
        active
        id
        price
        image
        product {
          id
          name
          metadata {
            description
            inventory
          }
        }
      }
    }
  }
`;

export default RegistryItemPage;
