import React from "react";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

const STRIPE_PUBLISHABLE_KEY = process.env.GATSBY_STRIPE_PUBLISHABLE_KEY;

export default class TakeMoney extends React.Component {
  constructor({ sku }) {
    super();
    this.state = {
      quantity: 1
    };
    if (typeof window !== 'undefined') {
      this.stripe = Stripe(STRIPE_PUBLISHABLE_KEY);
    }

    this.sku = sku;
    this.onQuantityChange = this.onQuantityChange.bind(this);
    this.onCheckoutClick = this.onCheckoutClick.bind(this);
  }

  onQuantityChange(e) {
    let newQuantity = e.target.value;
    if (newQuantity > this.props.inventory) {
      newQuantity = this.props.inventory;
    }
    if (newQuantity < 1) {
      newQuantity = 1;
    }
    this.setState({ quantity: newQuantity });
  }

  onCheckoutClick() {
    this.stripe
      .redirectToCheckout({
        items: [{ sku: this.sku, quantity: parseInt(this.state.quantity) }],
        successUrl: "https://marieclareandandrew.com/registry-thanks",
        cancelUrl: "https://marieclareandandrew.com/registry"
      })
      .then(function(result) {
        console.log("error");
      });
  }

  render() {
    return (
      <div>
        <div>
          <label>
            Quantity:
            <input
              type="number"
              id="item-quantity"
              onChange={this.onQuantityChange}
              value={this.state.quantity}
            />
          </label>
        </div>
        <Button onClick={this.onCheckoutClick}>Checkout</Button>
      </div>
    );
  }
}
